import _ from "underscore";
// import areaList from "@/assets/json/territory.json";
// import {stuEndingCalc, stuEndingPromotion} from "@/utils/apis";
import {delEP, SDepList, epGetSB, editEP, epGoods, epKwModule, stuEndingPromotion} from "@/utils/searchPromotion";
import { mapActions, mapGetters } from "vuex";
import {errMsg, isCompetion} from "@/utils/common";


export default {
    name: "PromotionPlan",
    data() {
        return {
            //是否显示修改输入框
            isShow: null,
            promotionPlanList: [],
            selectArr: [],
            //自定义预算
            customDrawer: false,
            customBudget: '',
            //时段 | 实时折扣系数
            timeDrawer: false,
            timeRadio: 1,
            tableData:[{
                title:'时段',
                '06':'1',
                '612':'',
                '1218':'',
                '1824':'',
            }],
            oneToSix: [
                {"type":0,"discount":100,"launch": 0,'timezone':'00:00-01:00','name':'oneToSix','index':0},
                {"type":1,"discount":100,"launch": 0,'timezone':'01:00-02:00','name':'oneToSix','index':1},
                {"type":2,"discount":100,"launch": 0,'timezone':'02:00-03:00','name':'oneToSix','index':2},
                {"type":3,"discount":100,"launch": 0,'timezone':'03:00-04:00','name':'oneToSix','index':3},
                {"type":4,"discount":100,"launch": 0,'timezone':'04:00-05:00','name':'oneToSix','index':4},
                {"type":5,"discount":100,"launch": 0,'timezone':'05:00-06:00','name':'oneToSix','index':5},
            ],
            sixToTwelve: [
                {"type":6,"discount":100,"launch":0,'timezone':'06:00-07:00','name':'sixToTwelve','index':0},
                {"type":7,"discount":100,"launch":0,'timezone':'07:00-08:00','name':'sixToTwelve','index':1},
                {"type":8,"discount":100,"launch":0,'timezone':'08:00-09:00','name':'sixToTwelve','index':2},
                {"type":9,"discount":100,"launch":0,'timezone':'09:00-10:00','name':'sixToTwelve','index':3},
                {"type":10,"discount":100,"launch":0,'timezone':'10:00-11:00','name':'sixToTwelve','index':4},
                {"type":11,"discount":100,"launch":0,'timezone':'11:00-12:00','name':'sixToTwelve','index':5},
            ],
            TwelveToEighteen: [
                {"type":12,"discount":100,"launch": 0,'timezone':'12:00-13:00','name':'TwelveToEighteen','index':0},
                {"type":13,"discount":100,"launch": 0,'timezone':'13:00-14:00','name':'TwelveToEighteen','index':1},
                {"type":14,"discount":100,"launch": 0,'timezone':'14:00-15:00','name':'TwelveToEighteen','index':2},
                {"type":15,"discount":100,"launch": 0,'timezone':'15:00-16:00','name':'TwelveToEighteen','index':3},
                {"type":16,"discount":100,"launch": 0,'timezone':'16:00-17:00','name':'TwelveToEighteen','index':4},
                {"type":17,"discount":100,"launch": 0,'timezone':'17:00-18:00','name':'TwelveToEighteen','index':5},
            ],
            EighteenToTwentyFour: [
                {"type":18,"discount":100,"launch": 0,'timezone':'18:00-19:00','name':'EighteenToTwentyFour','index':0},
                {"type":19,"discount":100,"launch": 0,'timezone':'19:00-20:00','name':'EighteenToTwentyFour','index':1},
                {"type":20,"discount":100,"launch": 0,'timezone':'20:00-21:00','name':'EighteenToTwentyFour','index':2},
                {"type":21,"discount":100,"launch": 0,'timezone':'21:00-22:00','name':'EighteenToTwentyFour','index':3},
                {"type":22,"discount":100,"launch": 0,'timezone':'22:00-23:00','name':'EighteenToTwentyFour','index':4},
                {"type":23,"discount":100,"launch": 0,'timezone':'23:00-24:00','name':'EighteenToTwentyFour','index':5}
            ],
            visible:false,
            hoverFormData: {
                show: false,
                timezone: null,
                discount: null,
                style: ''
            },
            clickFormData: {
                show: false,
                timezone: null,
                style: '',
                discount: 100,
                name: '',
                index: 0
            },
            isDisabled:false,
            rowTmp: null,
            //分页
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 2,
            },
            moduleTemplate: null,
            goodsList: [],
            theCurrentPlanId: null,
            radio5: 0,
            drawer2: false,
            deleteCollections: [],
            btnStatus: false,
            planTotalCost: [],
            store_bugget: [],
            leftCount: null
        }
    },
    mounted() {
        this.getPromotionList();
        // this.getSearchAds();
        this.goodsLists();
    },
    computed: {
        menus() {
            return this.$store.getters.dataMenuList;
        },
        isCompetition() {
            console.log("isC", isCompetion())
            return isCompetion() && this.leftCount !== null ;
        },
        eltagValue() {
            if (this.leftCount > 0) {
                return 'success';
            } else {
                return 'info';
            }
        },
        ...mapGetters([
            'amountValue',
            'moduleTemplateValue',
            'countValue',
            'totalCostValue'
        ])
    },
    methods: {
        ...mapActions([
            'setAmountValue',
            'setModuleTemplates',
            'setCountValue',
            'setTotalCostValue',
        ]),
        timeWrapClose() {
            this.clickFormData.show = false;
        },
        changeTimeSet (val) {
            if (Number(val) === 2) {
                this.clickFormData.discount = 100;
            }
        },
        deleteCollects(val) {
            this.deleteCollections = val;
        },
        confirmTimeSet() {
            let time_set = [];
            _.each(this.oneToSix, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.sixToTwelve, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.TwelveToEighteen, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.EighteenToTwentyFour, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });

            time_set = _.map(time_set, (item) => {
                let tmp = {
                    time: Number(item.type),
                    discount: Number(item.discount)
                }
                return tmp;
            });
            if (time_set.length === 0) {
                this.$message.warning('请设置推广时间段');
                return ;
            }

            let param = {
                id: this.theCurrentPlanId,
                time_set: JSON.stringify(time_set)
            }
            editEP( param).then( (res) => {
                if (res.code === 200) {
                    this.$notify({
                        type: 'success',
                        message: `时间设置${res.msg}`,
                        duration: 1000,
                        onClose: () => {
                            this.getPromotionList();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                console.log("err", err)
                this.$message.error(err.message);
            })
            this.timeDrawer = false
        },
        goodsLists() {
            epGoods().then( (res) => {
                if (res.code === 200) {
                    this.goodsList = res.data;
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(e => {
                console.log("err", e);
                this.$message.error(e.message)
            })
        },
        getSearchAds() {
            epKwModule().then((res) => {
                if (res.code === 200 && res.data.time_data
                    && res.data.area_data && res.data.people_data && res.data.store_data) {
                    this.moduleTemplate = {
                        time_data: JSON.parse(res.data.time_data),
                        area_data: JSON.parse(res.data.area_data),
                        people_data: JSON.parse(res.data.people_data),
                        store_offer_data: res.data.store_data.split('#')
                    }
                    this.btnStatus = false;
                    // console.log('mt', this.moduleTemplate);
                    // this.$lockr.set('moduleTemplate', this.moduleTemplate);
                    this.setModuleTemplates(this.moduleTemplate)
                    this.store_bugget = this.moduleTemplate.store_offer_data;
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        endingPromotion() {
            if (!this.deleteCollections.length) {
                return this.$message.warning("请选择要结束推广的计划")
            }
            const plan_ids = this.deleteCollections.map(item => {
                return item.id
            })
            stuEndingPromotion({id: plan_ids}).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: res.msg,
                        duration: 2000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    this.$message({
                        type: "warning",
                        message: res.msg,
                        duration: 3000,
                        onClose: () => {
                            window.location.reload()
                        }
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        /*
        * @param search_num 关键词展现量
        * @param position_num 排名数
        * @param total 总的展现数
        * @param store_offer  每个店铺的出价集合
        * @param time_nums 该计划投放的时间个数
        * */
        recursion(search_num, position_num, store_offer = [], time_nums, total=0, flag=1) {
            if (Number(flag) === Number(position_num)) {
                return [search_num, total];
            }
            //第一名的店铺的每个时间的出价
            let index = Number(flag) - 1;
            let hour_offer = Math.floor(this.store_bugget[index] / time_nums);
            this.store_bugget[index] = Number(this.store_bugget[index]) - hour_offer;
            let click_num = Math.floor(hour_offer / Number(store_offer[index]));
            let show_num = search_num - click_num;
            total += click_num;
            if (Number(show_num) <= 0) {
                return [0, total];
            }
            flag++;
            if (position_num === flag) {
                return [show_num, total];
            } else {
                return this.recursion(show_num, position_num, store_offer, time_nums, total, flag);
            }
            /*let show_num = Math.floor(search_num * (1 - click_rate));
            total += show_num;
            num--;
            if (num === 1) {
                return [show_num, total];
            } else {
                return this.recursion(show_num, click_rate, num, total);
            }*/
        },
        zoneRate(value=0) {
            if (value >= 1 && value <= 4) {
                return 1;
            } else if (value >= 4 && value <= 6) {
                return 2;
            } else if (value >= 7) {
                return 3;
            } else if (value >= -4 && value <= -1) {
                return -1;
            } else if (value > -7 && value <= -4) {
                return -2;
            } else if (value <= -7) {
                return -3;
            } else {
                return 0;
            }
        },
        //获取推广计划列表
        getPromotionList() {
            let param = {
                type: 'page',
                limit: this.listPages.eachPageNum,
                page: this.listPages.currentPageNum
            }
            SDepList(param).then( (res) => {
                if (res.code === 200) {
                    console.log("res", res)
                    this.promotionPlanList = res.data.data;
                    if (this.promotionPlanList.length === 0) {
                        this.$emit('upAmount', Number(this.amountValue));
                    }
                    if (res.data.count !== undefined) {
                        this.leftCount = res.data.count;
                        this.setCountValue(res.data.count)
                    }

                    this.listPages.total = res.data.total;
                    //统计计划日预算
                    this.planTotalCost = _.map(this.promotionPlanList, (item) => {
                        let tmp = {
                            plan_id: item.id,
                            daily_budget: Number(item.daily_budget)
                        }
                        return tmp;
                    });
                    this.setPlanTotalCost(this.planTotalCost);
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => {
                console.log("err", err)
                this.$message.error(err.message)
            })
        },
        //新建计划
        createPlan() {
            if (this.promotionPlanList.length >= 5) {
                this.$message({
                    type: 'warning',
                    message: '推广计划最多只能建5个',
                    duration: 2000
                });
                return;
            }
            this.$router.push({
                path:'/student/operationPromotion/createPlanStepOne'
            })
        },
        //删除（可以多选）
        delToggle() {
            if (this.deleteCollections.length === 0) {
                this.$message.warning('请选择要删除的计划');
                return ;
            }
            let ids = _.map(this.deleteCollections, (item) => {
                return item.id;
            });
            this.$confirm('此操作将永久删除已选的计划，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deletePlans(ids);
            }).catch(() => {
                console.log("取消操作");
            });

        },
        deletePlans(ids) {
            let param = {};
            if (ids.length > 1) {
                param.ids = ids.join(',');
            } else {
                param.ids = ids;
            }
            delEP({ids}).then( (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            this.getPromotionList();
                            // epGetSB().then( (res) => {
                            //     if (res.code === 200) {
                            //         this.$emit('upAmount', res.data.stu_balance);
                            //         window.location.reload();
                            //     } else {
                            //         this.$message.warning(res.msg);
                            //     }
                            // }).catch(e => {
                            //     console.log("err", e)
                            //     this.$message.error(e.message);
                            // })
                        }
                    });
                }
            }).catch(err => {
                this.$message.error(err.message);
                console.log("err", err)
            })
        },
        //结束推广
        endToggle() {
            const planNum = this.deleteCollections.length
            if (!planNum) {
                this.$message.warning("请选择要结束的计划")
                return
            }
            this.$confirm(`您确定要结束${planNum}个推广计划吗？`, '操作确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'btn-red',
                type: 'warning',
            }).then(() => {
                this.endingPromotion();
            }).catch((err) => {
                console.log(err);
                if (err !== 'cancel') {
                    this.$message({
                        type: 'warning',
                        message: '正在请求数据，请在页面刷新后重新结束推广，谢谢！',
                        onClose: () => {
                            // window.location.reload();
                        }
                    });
                } else {
                    this.$message.info('已取消操作');
                }

            });
        },
        //修改推广计划名称
        clickName(row) {
            this.isShow = row.id;
            //点开输入框  聚焦
            setTimeout(()=> {
                this.$refs[`nameInput_${row.id}`].focus();
            }, 100);
        },
        //失焦事件
        validateNum(val) {
            if (val === '') {
                this.$message.error('请输入推广计划名称');
                return
            } else {
                let param = {
                    id: val.id,
                    project_name: val.project_name,
                }
                editEP(param).then((res) => {
                    if (res.code === 200) {
                        this.isShow = null;
                        this.$notify({
                            title: '',
                            message: '修改成功！',
                            duration: 1000,
                            type: 'success',
                            onClose: () => {
                                this.getPromotionList();
                            }
                        });
                    }
                }).catch(err => {
                    errMsg(err)
                })
            }

        },
        //修改时段 | 实时折扣系数
        editTime(row) {
            this.timeDrawer = true;
            this.rowTmp = row;
            this.theCurrentPlanId = row.id;
            let time_set = JSON.parse(this.rowTmp.time_set);
            if (time_set.length === 24) {
                this.timeRadio = 1;
            } else {
                this.timeRadio = 2;
            }
            // console.log('time_set',time_set);
            this.oneToSix = _.map(this.oneToSix, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type)});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });
            this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type)});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });
            this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type)});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });

            this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type)});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });

            return;
        },
        selectShowWay(val){
            this.timeRadio = val;
            if (Number(val) === 2) {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
            } else {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
            }
        },
        setTime(name, index, discount) {
            if (Number(discount) === 0 && this.radio5 === 1) {
                this.$message.warning('折扣不能设置为0！');
                return;
            }
            switch (name) {
                case 'oneToSix':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.oneToSix, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.oneToSix, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.oneToSix, index, discount, 0);
                    }
                    break;
                case 'sixToTwelve':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.sixToTwelve, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.sixToTwelve, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.sixToTwelve, index, discount, 0);
                    }
                    break;
                case 'TwelveToEighteen':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, discount, 0);
                    }
                    break;
                case 'EighteenToTwentyFour':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, discount, 0);
                    }
                    break;
            }
            let isAllTime = 0;
            _.each(this.oneToSix, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.sixToTwelve, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.TwelveToEighteen, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.EighteenToTwentyFour, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            this.timeRadio = isAllTime === 24 ? 1 : 2;
            this.clickFormData.show = false;
        },
        setTimeZoneParam(obj, index, discount, launch) {
            obj[index].discount = Number(discount);
            obj[index].launch = launch;
        },
        clickDialog(item, evt) {
            if (Number(item.discount) === 100) {
                this.radio5 = 2;
            } else if (Number(item.launch === 1)) {
                this.radio5 = 1;
            } else {
                this.radio5 = 3;
            }
            let winClientWidth = document.body.clientWidth;
            this.closeHoverD();
            this.clickFormData.timezone = item.timezone;
            this.clickFormData.discount = Number(item.discount);
            this.clickFormData.name = item.name;
            this.clickFormData.show = true;
            this.clickFormData.index = item.index;
            let x = evt.clientX + 20;
            let y = evt.clientY + 20;
            if(winClientWidth - 246 < x){
                this.clickFormData.style = {
                    left: `${winClientWidth - 246}px`,
                    top: `${y}px`
                }
            }
            else {
                this.clickFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        hoverDialog(item, evt) {
            let winClientWidth = document.body.clientWidth;
            this.hoverFormData.timezone = item.timezone;
            this.hoverFormData.discount = item.discount;
            this.hoverFormData.show = true;
            let x = evt.clientX + 4;
            let y = evt.clientY - 100;
            if(winClientWidth - 210 < x){
                this.hoverFormData.style = {
                    left: `${winClientWidth - 210 }px`,
                    top: `${y}px`
                }
            } else {
                this.hoverFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        closeHoverD() {
            this.hoverFormData.show = false;
        },
        //修改自定义预算
        editDailyBudget(row) {
            this.customDrawer = true;
            this.customBudget = Number(row.daily_budget);
            this.theCurrentPlanId = row.id;
            this.rowTmp = row;
        },
        validatePlanCost(plan_id, daily_bugget) {
            let planId = Number(plan_id);
            let dailyBugget = Number(daily_bugget);
            // let amount = this.$lockr.get('amount');
            // let amount = this.useSearchStore.amount;
            let amount = this.amountValue;
            if (dailyBugget > amount) {
                this.$message({
                    type: 'warning',
                    message: '日预算不能超过设定的推广余额',
                    duration: 2000
                });
                return false;
            }
            let total = 0;
            let total_cost_arr = _.map(this.planTotalCost, (_val) => {
                if (Number(_val.plan_id) === planId) {
                    total += dailyBugget;
                    _val.daily_budget = dailyBugget;
                } else {
                    total += Number(_val.daily_budget);
                }
                return _val;
            });
            // console.log('@@', total_cost_arr);
            if (total > amount) {
                this.$message({
                    type: 'warning',
                    message: '计划的日预算总和不能超过设定的推广余额',
                    duration: 2000
                });
                return false;
            }
            this.setPlanTotalCost(total_cost_arr);
            return true;
        },
        setPlanTotalCost(plan_total_cost) {
            this.planTotalCost = plan_total_cost;
            this.setTotalCostValue(plan_total_cost)
        },
        //保存预算
        saveDailyBudget() {
            if (isNaN(Number(this.customBudget)) || Number(this.customBudget) === 0) {
                this.$message({
                    type: 'warning',
                    message: '日预算不能为空或者为0',
                    duration: 1000
                });
                return;
            }
            //验证当前修改之后的日预算后，计划中的总日预算是否会超过给定的推广余额
            let validateRes = this.validatePlanCost(this.theCurrentPlanId, this.customBudget);
            if (!validateRes) {
                return;
            }
            this.customDrawer = false;
            this.rowTmp.daily_budget = this.customBudget;
            let param = {
                id: this.theCurrentPlanId,
                daily_budget: Number(this.customBudget)
            }
              editEP(param).then( (res) => {
                if (res.code === 200) {
                    this.$notify({
                        type: 'success',
                        duration: 1000,
                        message: `每日预算${res.msg}`,
                        onClose: () => {
                            this.getPromotionList();
                        }
                    });
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                    });
                }
            }).catch(err => {
                errMsg(err)
              })
        },
        //删除（单条）
        delBtn(id) {
            this.$confirm('您确定要删除该推广计划吗？', '操作确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'btn-red',
                type: 'warning',
            }).then(() => {
                let ids = [id];
                this.deletePlans(ids);
            }).catch(() => {
                this.$message.info('已取消删除！');
            });
        },
        //分页
        handleSizeChange(val) {
            this.listPages.eachPageNum = val;
            this.getPromotionList();
        },
        handleCurrentChange(val) {
            this.listPages.currentPageNum = val;
            this.getPromotionList();
        }
    }
}
